<template lang='pug'>
.page-wrap
	h3 Pages
	.row
		.heading Path
		.heading Views
		.heading Duration
	div(v-for='page in pages')
		.row
			.path {{page.path}}
			.views {{page.views}}
			.time {{page.duration}}
</template>

<script>

export default{
	data(){
		return{
			pages:Array
		}
	},
	async created(){
		let pid = this.$route.params.key;
		let date = this.$route.params.date;
		await fetch('/api/v1/pages?pid='+pid, {credentials: 'include'})
		.then(response => response.json())
		.then(response => {
			if (response.pages){
				this.pages = response.pages;
			}	else{
				this.$router.push('/admin/dashboard');
			}
		});
	}
}

</script>

<style lang="stylus" scoped>
h3
	margin-bottom 30px
.row
	display flex
	padding 10px 0
	border-bottom 1px solid #eee
	.path, .views, .time, .heading
		flex 1
	.heading
		font-weight 500
</style>